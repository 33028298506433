/* eslint-disable */

import last from 'lodash/last';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import moment from 'moment';
import { AppConstants } from '../utils/app.constants';
import { iff } from './iff';

export const isAccessTokenAvailable = () => !!localStorage.getItem('tokens');
export const setAccessToken = (token) => localStorage.setItem('tokens', token);
export const getAccessToken = () => localStorage.getItem('tokens') || {};
export const clearAccessToken = () => localStorage.removeItem('tokens');

export const getProgressByValues = (isPasswordValid, isMobileNumberValid, termsCheckbox) => {
  let count = 1;
  if (isPasswordValid) {
    count++;
  }
  if (isMobileNumberValid) {
    count++;
  }
  if (termsCheckbox) {
    count++;
  }
  return count;
};

export const isEmptyStr = (str) => {
  if (typeof str === 'string' && str.length > 0) {
    return true;
  }
  return false;
};

export const getHeaders = () => ({
  common: {
    Authorization: `Bearer ${JSON.parse(getAccessToken()).access_token}`
  }
});

export const getLangHeaders = (overrideLanguage) => {
  let lang = localStorage.getItem('i18nextLng') || 'en';
  if (lang === 'pt') {
    lang = 'pt-BR';
  }
  return {
    common: {
      Authorization: `Bearer ${JSON.parse(getAccessToken()).access_token}`,
      'Accept-Language': overrideLanguage || lang
    }
  };
};

export const getInitialName = (name) => {
  if (isEmpty(name)) {
    return '';
  }
  const splitedNames = name.split(' ');
  const firstName = first(splitedNames) || '';
  const lastName = last(splitedNames) || '';
  if (splitedNames.length >= 2) {
    return `${firstName.charAt(0).toUpperCase()} ${lastName.charAt(0).toUpperCase()}`;
  }
  return firstName.charAt(0).toUpperCase();
};

export const getShortName = (name) => {
  if (isEmpty(name)) {
    return '';
  }
  const splitedNames = name.split(' ');
  const firstName = first(splitedNames) || '';
  const lastName = last(splitedNames) || '';
  if (splitedNames.length >= 2) {
    return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
  }
  return firstName.charAt(0).toUpperCase();
};

export const setTimeoutPromise = async (time) =>
  new Promise((resolve) => setTimeout(resolve, time));

export const isSearchSubStringFound = (searchString = '', fields = []) =>
  !isEmpty(
    fields.filter((field) => (field || '').toLowerCase().indexOf(searchString.toLowerCase()) >= 0)
  );

export const CommaSeparatedList = (a, b) => {
  try {
    if (!b) {
      return a.join(', ');
    }
    return get(a, b).join(', ');
  } catch (e) {
    // do nothing
    return '';
  }
};

export const getSecondaryStatus = (status, startDate, endDate) => {
  if (
    (startDate &&
      status === 'Planned' &&
      moment().diff(moment(startDate).format('YYYY-MM-DD'), 'days') > 0) ||
    (endDate &&
      status === 'In progress' &&
      moment().diff(moment(endDate).format('YYYY-MM-DD'), 'days') > 0)
  ) {
    return 'DELAYED';
  }
  return '';
};

export const getAppHostsInfo = () => {
  const domainMapping = {
    'localhost:3000': { host: 'GLOBAL', account: 'qa.accounts.cropwise.com' },
    'localhost:4000': { host: 'UK', account: 'uk-qa.accounts.cropwise.com' },
    'localhost:5000': { host: 'UK', account: 'qa.accounts.syngentadigital.co.uk' },
    'dev.app.cropwise.com': { host: 'GLOBAL', account: 'dev.accounts.cropwise.com' },
    'uk-dev.app.cropwise.com': { host: 'UK', account: 'uk-dev.accounts.cropwise.com' },
    'dev.app.syngentadigital.co.uk': { host: 'UK', account: 'dev.accounts.syngentadigital.co.uk' },
    'qa.app.cropwise.com': { host: 'GLOBAL', account: 'qa.accounts.cropwise.com' },
    'staff.qa.cropwise.com': { host: 'GLOBAL', account: 'qa.accounts.cropwise.com' },
    'uk-qa.app.cropwise.com': { host: 'UK', account: 'uk-qa.accounts.cropwise.com' },
    'qa.app.syngentadigital.co.uk': { host: 'UK', account: 'qa.accounts.syngentadigital.co.uk' },
    'staging.app.cropwise.com': { host: 'GLOBAL', account: 'staging.accounts.cropwise.com' },
    'uk-staging.app.cropwise.com': { host: 'UK', account: 'uk-staging.accounts.cropwise.com' },
    'staging.app.syngentadigital.co.uk': {
      host: 'UK',
      account: 'staging.accounts.syngentadigital.co.uk'
    },
    'app.cropwise.com': { host: 'GLOBAL', account: 'accounts.cropwise.com' },
    'uk.app.cropwise.com': { host: 'UK', account: 'uk.accounts.cropwise.com' },
    'app.syngentadigital.co.uk': { host: 'UK', account: 'accounts.syngentadigital.co.uk' }
  };

  const { host } = window.location;
  return {
    host: (!isEmpty(domainMapping[host]) && domainMapping[host].host) || 'GLOBAL',
    account: !isEmpty(domainMapping[host]) && domainMapping[host].account,
    hostsList: [...new Set(Object.values(domainMapping).map((d) => d.host))]
  };
};

export const isUK = () => {
  const { host } = getAppHostsInfo();
  if (host === 'UK') {
    return true;
  }
  return false;
};

export const getUrl = (baseUrl, queryParams) => {
  if (Object.keys(queryParams).length > 0) {
    let delimiter, paramValue;
    let isFirstParam = false;
    const urlWithQueryParams = Object.keys(queryParams).reduce((parameterString, key) => {
      const paramKey = encodeURIComponent(key);
      paramValue = encodeURIComponent(queryParams[key]);
      if (paramValue && !isFirstParam) {
        delimiter = '?';
        isFirstParam = true;
      } else {
        delimiter = '&';
      }

      if (paramValue || typeof val === 'number') {
        return [parameterString, delimiter, paramKey, '=', paramValue].join('');
      } else {
        return parameterString;
      }
    }, baseUrl);
    return urlWithQueryParams;
  } else {
    return baseUrl;
  }
};

export const getSorter = (sorter) => {
  let sort = '';
  if (Object.keys(sorter).length > 0 && sorter.order) {
    if (sorter.order === 'descend') {
      sort = `${sorter.field}${','}${sorter.order.slice(0, 4)}`;
    } else {
      sort = `${sorter.field}${','}${sorter.order.slice(0, 3)}`;
    }
  }
  return sort;
};

export const getAccountsOAuthUrl = () => {
  let { account } = getAppHostsInfo();
  const { O_AUTH } = AppConstants;
  return `https://${account}/oauth/authorize?response_type=${O_AUTH.responseCode}&client_id=${O_AUTH.clientId}&redirect_uri=${location.origin}/authenticate`;
};

export const getAccountsTnCUrl = () => {
  const { account } = getAppHostsInfo();
  return `https://${account}/terms-and-conditions?referrer_url=${location.origin}`;
};

export const getLocale = () => {
  let lang = localStorage.getItem('i18nextLng') || 'en';
  if (lang === 'pt') {
    lang = 'pt-BR';
  }
  return lang;
};

export const localeForCatalog = (queryStringPrefix) => {
  let location = '@BR';
  if (localStorage.getItem('localisedCatalogOff')) {
    if (queryStringPrefix) {
      return '';
    }
    return {};
  }
  if (isUK()) {
    location = '@GB';
  }
  if (queryStringPrefix) {
    return `${queryStringPrefix}location=${location}`;
  }
  return { location };
};

export const getRowsAccordingToWindowHeight = (lessRows = 0) =>
  Math.floor(
    iff(
      window.innerHeight < 800,
      window.innerHeight,
      window.innerHeight + (window.innerHeight - 800)
    ) / 100
  ) - lessRows;

export const sortByName = (sortArray) => {
  sortArray.sort(function (a, b) {
    let x = a.name.toLowerCase();
    let y = b.name.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  });
};

export const getLanguageCode = (lang) => {
  const selectedLang = lang || navigator.language;
  let selectedCode = selectedLang.includes('fr')
    ? 'fr'
    : selectedLang.includes('en')
    ? 'en'
    : selectedLang.includes('pt')
    ? 'pt-BR'
    : selectedLang.includes('de')
    ? 'de'
    : 'en';
  return selectedCode;
};
